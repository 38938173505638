import { Flex, Text, useMatchBreakpoints, Box, Grid } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useAccount } from 'wagmi'
import Image from 'next/image'

const Home1 = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { isTablet, isDesktop } = useMatchBreakpoints()

  return (
    <Box width="100%" py="50px" px={['15px', , '40px']} id='home-1'>
      <Box maxWidth={['100%', null, '1280px']} mx="auto">
        <Grid gridTemplateColumns={['1fr', , , '1fr 1fr']} gridGap="20px">
          <Flex flexDirection="column" bg="#2F2235" borderRadius="20px" p="30px">
            <Flex
              style={{ gap: '10px' }}
              p="10px 15px"
              borderRadius="23px"
              mb="30px"
              bg="#9B72AF"
              width="auto"
              mr="auto"
            >
              <Image src="/images/home/icon/icon1.png" alt="icon1" width={26} height={24} />
              <Text>Web app</Text>
            </Flex>
            <Text fontSize="34px" mb="58px" fontWeight={500} lineHeight="41px">
              Swapping made simple. Access thousands of tokens on 8+ chains.
            </Text>
            <Flex style={{ gap: '6px' }} flexDirection="column" mt="auto">
              <Flex
                alignItems="center"
                justifyContent="space-between"
                bg="white"
                borderRadius="20px"
                p={20}
                flexWrap="wrap"
              >
                <Flex style={{ gap: '15px' }}>
                  <Image src="/images/home/icon/icon-eth.png" alt="eth" height={34} width={34} />
                  <Text fontSize="22px" color="#2F2C2C" fontWeight={500}>
                    Ethereum{' '}
                    <Text as="span" color="#8C989A" fontSize={22}>
                      ETH
                    </Text>
                  </Text>
                </Flex>
                <Flex alignItems="center" style={{ gap: '10px' }}>
                  <Text fontSize="22px" color="#2F2C2C" fontWeight={500}>
                    $3,012.57
                  </Text>
                  <Image src="/images/home/icon/icon-down.svg" alt="down" width={8} height={6} />
                  <Text fontSize="22px" color="#FF6262" fontWeight={500}>
                    1.84%
                  </Text>
                </Flex>
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                bg="white"
                borderRadius="20px"
                p={20}
                flexWrap="wrap"
              >
                <Flex style={{ gap: '15px' }}>
                  <Image src="/images/home/icon/icon-usdc.png" alt="usdc" height={34} width={34} />
                  <Text fontSize="22px" color="#2F2C2C" fontWeight={500}>
                    USD Coin{' '}
                    <Text as="span" color="#8C989A" fontSize={22}>
                      USDC
                    </Text>
                  </Text>
                </Flex>
                <Flex alignItems="center" style={{ gap: '10px' }}>
                  <Text fontSize="22px" color="#2F2C2C" fontWeight={500}>
                    $1.00
                  </Text>
                  <Image src="/images/home/icon/icon-up.svg" alt="up" width={8} height={6} />
                  <Text fontSize="22px" color="#48CE6E" fontWeight={500}>
                    0.00%
                  </Text>
                </Flex>
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                bg="white"
                borderRadius="20px"
                p={20}
                flexWrap="wrap"
              >
                <Flex style={{ gap: '15px' }}>
                  <Image src="/images/home/icon/icon-uni.png" alt="uni" height={34} width={34} />
                  <Text fontSize="22px" color="#2F2C2C" fontWeight={500}>
                    Uniswap{' '}
                    <Text as="span" color="#8C989A" fontSize={22}>
                      UNI
                    </Text>
                  </Text>
                </Flex>
                <Flex alignItems="center" style={{ gap: '10px' }}>
                  <Text fontSize="22px" color="#2F2C2C" fontWeight={500}>
                    $1.98
                  </Text>
                  <Image src="/images/home/icon/icon-down.svg" alt="down" width={8} height={6} />
                  <Text fontSize="22px" color="#FF6262" fontWeight={500}>
                    2.07%
                  </Text>
                </Flex>
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                bg="white"
                borderRadius="20px"
                p={20}
                flexWrap="wrap"
              >
                <Flex style={{ gap: '15px' }}>
                  <Image src="/images/home/icon/icon-ldo.png" alt="ldo" height={34} width={34} />
                  <Text fontSize="22px" color="#2F2C2C" fontWeight={500}>
                    Lido DAO{' '}
                    <Text as="span" color="#8C989A" fontSize={22}>
                      LDO
                    </Text>
                  </Text>
                </Flex>
                <Flex alignItems="center" style={{ gap: '10px' }}>
                  <Text fontSize="22px" color="#2F2C2C" fontWeight={500}>
                    $1.98
                  </Text>
                  <Image src="/images/home/icon/icon-up.svg" alt="up" width={8} height={6} />
                  <Text fontSize="22px" color="#48CE6E" fontWeight={500}>
                    3.29%
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex flexDirection="column" bg="#161F34" borderRadius="20px" p="30px 30px 0 30px">
            <Flex
              style={{ gap: '10px' }}
              p="10px 15px"
              borderRadius="23px"
              mb="30px"
              bg="#50689E"
              width="auto"
              mr="auto"
            >
              <Image src="/images/home/icon/icon2.png" alt="icon2" width={22} height={22} />
              <Text>Cybria Wallet</Text>
            </Flex>
            <Text fontSize="34px" mb="58px" fontWeight={500} lineHeight="41px">
              The wallet built for swapping. Available on iOS and Android.
            </Text>
            <Flex justifyContent="center" mt="auto" position="relative">
              <Image src="/images/home/icon/image1.png" alt="image1" height={374} width={402} />
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            background="url('/images/home/icon/bg1.png'), #2B3C16"
            backgroundRepeat="no-repeat"
            backgroundPosition="right"
            borderRadius="20px"
            p="30px"
          >
            <Flex
              style={{ gap: '10px' }}
              p="10px 15px"
              borderRadius="23px"
              mb="100px"
              bg="#A9B535"
              width="auto"
              mr="auto"
            >
              <Image src="/images/home/icon/icon1.png" alt="icon2" width={26} height={24} />
              <Text>Developer docs</Text>
            </Flex>
            <Text fontSize="34px" fontWeight={500} lineHeight="41px" maxWidth={346}>
              Build the next generation of open applications and tools.
            </Text>
          </Flex>
          <Flex
            flexDirection="column"
            background="url('/images/home/icon/bg2.png'), #132E2C"
            backgroundRepeat="no-repeat"
            backgroundPosition="top 0 right 22px"
            borderRadius="20px"
            p="30px"
          >
            <Flex
              style={{ gap: '10px' }}
              p="10px 15px"
              borderRadius="23px"
              mb="100px"
              bg="#41AEA8"
              width="auto"
              mr="auto"
            >
              <Image src="/images/home/icon/icon1.png" alt="icon2" width={26} height={24} />
              <Text>Liquidity</Text>
            </Flex>
            <Text fontSize="34px" fontWeight={500} lineHeight="41px" maxWidth={346}>
              Provide liquidity to pools on the Cybria Protocol and earn fees on swaps.
            </Text>
          </Flex>
        </Grid>
      </Box>
    </Box>
  )
}

export default Home1
