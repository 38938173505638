import styled from 'styled-components'
import { Box, Button, Flex, PageSection, Text } from '@pancakeswap/uikit'
import { useAccount } from 'wagmi'
import useTheme from 'hooks/useTheme'
import Container from 'components/Layout/Container'
import { useTranslation } from '@pancakeswap/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { ChainId } from '@pancakeswap/sdk'
import Image from 'next/image'
import { StyledInputCurrencyWrapper, StyledSwapContainer } from 'views/Swap/styles'
import { AppBody } from 'components/App'
import { V3SwapForm } from 'views/Swap/V3Swap'
import Link from 'next/link'
import { useCallback } from 'react'
import Home1 from './components/Home1'
import Home2 from './components/Home2'
import Home3 from './components/Home3'
import Hero from './components/Hero'
import { swapSectionData, earnSectionData, cakeSectionData } from './components/SalesSection/data'
import MetricsSection from './components/MetricsSection'
import SalesSection from './components/SalesSection'
import WinSection from './components/WinSection'
import FarmsPoolsRow from './components/FarmsPoolsRow'
import Footer from './components/Footer'
import CakeDataRow from './components/CakeDataRow'
import { WedgeTopLeft, InnerWedgeWrapper, OuterWedgeWrapper, WedgeTopRight } from './components/WedgeSvgs'
import UserBanner from './components/UserBanner'
import MultipleBanner from './components/Banners/MultipleBanner'

const StyledHeroSection = styled(PageSection)`
  padding-top: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 48px;
  }
`

const UserBannerWrapper = styled(Container)`
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding-left: 0px;
  padding-right: 0px;

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-left: 24px;
    padding-right: 24px;
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const { theme, isDark } = useTheme()
  const { address: account } = useAccount()
  const { chainId } = useActiveChainId()

  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '968px' }

  const { t } = useTranslation()

  const scrollToElement = useCallback(() => {
    const scrollDiv = document.getElementById('home-1').offsetTop
    window.scrollTo({
      top: scrollDiv,
      behavior: 'smooth',
    })
  }, [])

  return (
    <>
      {/* <style jsx global>
        {`
          #home-1 .page-bg {
            background: transparent;
          }
          [data-theme='dark'] #home-1 .page-bg {
            background: transparent;
          }
          #home-2 .page-bg {
            background: transparent;
          }
          [data-theme='dark'] #home-2 .page-bg {
            background: transparent;
          }
          #home-3 .page-bg {
            background: transparent;
          }
          [data-theme='dark'] #home-3 .page-bg {
            background: transparent;
          }
          #home-4 .inner-wedge svg {
            fill: #ffffff;
          }
          [data-theme='dark'] #home-4 .inner-wedge svg {
            fill: #08060b;
          }
          #home-5 .inner-wedge svg {
            fill: #ffffff;
          }
          [data-theme='dark'] #home-5 .inner-wedge svg {
            fill: #08060b;
          }
        `}
      </style> */}
      {/* <StyledHeroSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        containerProps={{
          id: 'home-1',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        {account && chainId === ChainId.BSC && (
          <UserBannerWrapper>
            <UserBanner />
          </UserBannerWrapper>
        )}
        <MultipleBanner />
        <Box mb="84px">
          <Image src={bannerHome} alt='banner home' />
        </Box>
        <Hero />
      </StyledHeroSection> */}
      {/* <PageSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        containerProps={{
          id: 'home-2',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <MetricsSection />
      </PageSection>
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={isDark ? 'transparent' : 'transparent'}
        containerProps={{
          id: 'home-4',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <OuterWedgeWrapper>
          <InnerWedgeWrapper top>
            <WedgeTopLeft />
          </InnerWedgeWrapper>
        </OuterWedgeWrapper>
        <SalesSection {...swapSectionData(t)} />
      </PageSection>
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={isDark ? 'transparent' : 'transparent'}
        index={2}
        hasCurvedDivider={false}
        containerProps={{
          id: 'home-5',
        }}
      >
        <OuterWedgeWrapper>
          <InnerWedgeWrapper width="150%" top>
            <WedgeTopRight />
          </InnerWedgeWrapper>
        </OuterWedgeWrapper>
        <SalesSection {...earnSectionData(t)} />
        <FarmsPoolsRow />
      </PageSection>
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        containerProps={{
          id: 'home-3',
        }}
        index={2}
        hasCurvedDivider={false}
      >
        <WinSection />
      </PageSection>
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background={isDark ? 'transparent' : 'transparent'}
        index={2}
        hasCurvedDivider={false}
      >
        <SalesSection {...cakeSectionData(t)} />
        <CakeDataRow />
      </PageSection>
      <PageSection
        innerProps={{ style: HomeSectionContainerStyles }}
        background="url(/images/home/bg-footer.png)"
        backgroundPosition="center"
        index={2}
        hasCurvedDivider={false}
      >
        <Footer />
      </PageSection> */}
      <Flex
        background="url(/images/home/bg-home-1.png), linear-gradient(180deg, #1A1A1A 0%, #000000 100%)"
        backgroundPosition="top"
        backgroundRepeat="no-repeat"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100vh - 56px)"
      >
        <Flex flexDirection="column" width="100%" maxWidth="480px" alignItems="center" py="30px">
          <Text fontSize={['40px', , '60px']} textAlign="center" fontWeight="600" mb="35px">
            Swap anytime, anywhere.
          </Text>
          <StyledInputCurrencyWrapper>
            <AppBody>
              <V3SwapForm showHeader={false} />
            </AppBody>
          </StyledInputCurrencyWrapper>
          <Text mb="110px" mt="30px" textAlign="center">
            The largest onchain marketplace. Buy and sell crypto on Ethereum and 7+ other chains.
          </Text>
          <Flex
            style={{ cursor: 'pointer', gap: '8px' }}
            onClick={scrollToElement}
            flexDirection="column"
            alignItems="center"
          >
            <Text>Scroll to learn more</Text>{' '}
            <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 1L7 7L13 1"
                stroke="#5F6667"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Flex>
        </Flex>
      </Flex>
      <Home1 />
      <Home2 />
      <Home3 />
    </>
  )
}

export default Home
