import styled from 'styled-components'
import { Flex, Text, useMatchBreakpoints, Box, Grid } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useAccount } from 'wagmi'
import Image from 'next/image'
import Link from 'next/link'

const Home3 = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { isTablet, isDesktop } = useMatchBreakpoints()

  return (
    <Box width="100%" pb="50px" pt={168} px={['15px', , '40px']}>
      <Box maxWidth={['100%', null, '1280px']} mx="auto">
        <Grid gridTemplateColumns={['1fr', , '1fr 1fr', '1fr 1fr 1fr 1fr']} gridGap="20px">
          <Flex flexDirection="column" bg="#F6EEEE" p={30} borderRadius={20}>
            <Flex bg="#FFFFFF" borderRadius={23} p="10px 15px" mr="auto" style={{ gap: '10px' }} mb={104} alignItems="center">
              <Image src="/images/home/icon/icon5.png" alt="icon5" width={26} height={26} />
              <Text fontSize={18} color="#B69B9B">
                Help Center
              </Text>
            </Flex>
            <Text fontSize={25} fontWeight={500} color="#7A6363" mt="auto">
              Get support
            </Text>
          </Flex>
          <Flex flexDirection="column" bg="#F1EEF4" p={30} borderRadius={20}>
            <Flex bg="#FFFFFF" borderRadius={23} p="10px 15px" mr="auto" style={{ gap: '10px' }} mb={104} alignItems="center">
              <Image src="/images/home/icon/icon6.png" alt="icon5" width={24} height={22} />
              <Text fontSize={18} color="#A59BAE">
                Blog
              </Text>
            </Flex>
            <Text fontSize={25} fontWeight={500} lineHeight="29px" color="#7A6363" mt="auto">
              Insights and news from the team
            </Text>
          </Flex>
          <Grid flexDirection="column" bg="#151515" p={30} borderRadius={20} style={{boxShadow: '4px 4px 1px 0px #FFFFFF80'}} gridColumn={['span 1',,'span 2', 'span 2']}>
            <Flex bg="#FFFFFF" borderRadius={23} p="10px 15px" mr="auto" style={{ gap: '10px' }} mb={104} alignItems="center">
              <Image src="/images/home/icon/icon7.png" alt="icon5" width={28} height={26} />
              <Text fontSize={18} color="#000000">
              Stay connected
              </Text>
            </Flex>
            <Text fontSize={25} fontWeight={500} lineHeight="29px" color="#ffffff" mt="auto">
              Follow @Cybria on X for the latest updates
            </Text>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Home3
