import styled from 'styled-components'
import { Flex, Text, useMatchBreakpoints, Box, Grid } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useAccount } from 'wagmi'
import Image from 'next/image'
import Link from 'next/link'

const Home2 = () => {
  const { t } = useTranslation()
  const { address: account } = useAccount()
  const { isTablet, isDesktop } = useMatchBreakpoints()

  return (
    <Box width="100%" py="50px" px={['15px', , '40px']}>
      <Box maxWidth={['100%', null, '1280px']} mx="auto">
        <Grid gridTemplateColumns={['1fr', , , '1fr 1fr']} gridGap="20px">
          <Flex flexDirection="column">
            <Text fontSize={48} fontWeight={500} mb={30}>
              Trusted by millions
            </Text>
            <Text fontSize={18} mb={50} mt="auto">
              Cybria Swap products are powered by the Cybria Swap Protocol. The protocol is the largest onchain
              marketplace, with billions of dollars in weekly volume across thousands of tokens on Ethereum and 7+
              additional chains.
            </Text>
            <Link
              href="#"
              style={{
                display: 'flex',
                gap: '10px',
                background: '#8F60FF',
                borderRadius: '28px',
                padding: '15px 30px',
                marginRight: 'auto'
              }}
            >
              <Text>Learn more</Text>
              <Image src="/images/home/icon/icon3.png" alt="icon3" width={26} height={26} />
            </Link>
          </Flex>
          <Grid gridTemplateColumns={['1fr', , '1fr 1fr', '1fr 1fr']} style={{gap: '20px'}}>
            <Flex flexDirection="column" bg="#151515" borderRadius={20} p={30} style={{boxShadow: '4px 4px 1px 0px #FFFFFF80'}}>
              <Text color='#BABABA' fontSize={24} mb={84}>All time volume</Text>
              <Text color='white' fontSize={48} mt="auto">$2.0T</Text>
            </Flex>
            <Flex flexDirection="column" bg="#151515" borderRadius={20} p={30} style={{boxShadow: '4px 4px 1px 0px #FFFFFF80'}}>
              <Text color='#BABABA' fontSize={24} mb={84}>All time swappers</Text>
              <Text color='white' fontSize={48} mt="auto">16.6M</Text>
            </Flex>
            <Flex flexDirection="column" bg="#151515" borderRadius={20} p={30} style={{boxShadow: '4px 4px 1px 0px #FFFFFF80'}}>
              <Text color='#BABABA' fontSize={24} mb={84}>All time LP fees</Text>
              <Text color='white' fontSize={48} mt="auto">$3.4B</Text>
            </Flex>
            <Flex flexDirection="column" bg="#F2FFF6" borderRadius={20} p={30}>
              <Flex alignItems="center" style={{gap: '10px'}} mb={84}>
              <Image src="/images/home/icon/icon4.svg" alt='icon4' width={14} height={14} />
              <Text color='#48CE6E' fontSize={24}>24H volume</Text>
              </Flex>
              <Text color='#48CE6E' fontSize={48} mt="auto">$3.4B</Text>
            </Flex>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Home2
